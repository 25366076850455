import { template as template_3b895b2d27d54f92a874eea4de5517ea } from "@ember/template-compiler";
const FKText = template_3b895b2d27d54f92a874eea4de5517ea(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
